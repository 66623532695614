<template>
    <div class="phone-template-wrapper__container phone-bg-shadow">
        <div class="all-phones" :class="expanded ? 'expanded' : ''">
            <div class="all-phones__header">
            <h2>Hersteller Auswählen</h2>
            </div>
            <div class="all-phones__phone-wrapper d-flex justify-content-center">
                <div class="phone-box">
                    <div class="phone-wrapper">
                        <div class="phone-wrapper__select">
                            <button>

                            <span :class="['arrow', 'arrow-' + (dropdownMenu ? 'down' : 'left')]">
                                <img :src="require('@/assets/images/intro13/compare-icon-white.svg')" />
                            </span>
                            </button>
                        </div>
                        <div class="all-phones__top__browse" v-if="dropdownMenu">
                            <div v-if="manufacturers && dropdownMenu">
                            <template v-if="!selectedManufacturer">
                              <template v-for="(manufacturer, index) in manufacturers">
                                <a v-if="activePhones.includes(manufacturer.id)" :key="'mnf-' + index" v-text="manufacturer.title"
                                  @click="selectedManufacturer = manufacturer.id"></a>
                              </template>
                            </template>
                            <template v-else>
                                <span class="back-btn" @click="selectedManufacturer = null">
                                    <img :src="require('@/assets/images/intro13/compare-icon-white.svg')" />
                                </span>
                                <a v-for="(model, index) in phoneModels"
                                :key="'model-'+  index"
                                @click="handleModel(model)"
                                v-text="model.name"></a>
                            </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      selectedManufacturer: null,
      dropdownMenu: !this.phone,
      expanded: false,
      manufacturers: [
        { id: 3, title: 'Apple' },
        { id: 9, title: 'Fairphone' },
        { id: 4, title: 'Google' },
        { id: 8, title: 'LG' },
        { id: 6, title: 'Motorola' },
        { id: 7, title: 'Nokia' },
        { id: 5, title: 'Nothing' },
        { id: 1, title: 'Samsung' },
        { id: 10, title: 'Telekom' },
        { id: 2, title: 'Xiaomi' },
        { id: 11, title: 'Crosscall' },
        { id: 12, title: 'Sonstige' }
      ]
    }
  },
  computed: {
    // ...mapGetters('smartphoneComparison', ['firstPhone', 'secondPhone', 'thirdPhone']),
    phoneModels () {
      return this.$store.state?.smartphoneComparison?.phones.filter(
        phone => parseInt(phone.manufacturer) === parseInt(this.selectedManufacturer)
      )
    },
    activePhones () {
      return Array.from(new Set(this.$store.state?.smartphoneComparison?.phones.map(phone => parseInt(phone.manufacturer))))
    }
  },
  methods: {
    handleModel (phone) {
      this.$store.commit('smartphoneComparison/setActivePhone', phone)
      this.selectedManufacturer = null
      this.$store.commit('smartphoneComparison/setActiveView', 'slider')
    }
  }
}
</script>
