<template>
    <div class="a-slide-content">
      <!-- <div class="a-slide-content-wrapp" @click.self="$emit('handleChange')"> -->
      <div class="a-slide-content-wrapp position-relative">
        <div class="a-slide-content-wrapp-title" v-text="item.title"></div>
        <router-link :to="{ path: item.slide_stikcy_image_link }" class="a-slide-content-wrapp-logo" v-if="item.slide_stikcy_image">
          <div>
            <img :src="baseUrl + item.slide_stikcy_image" alt="">
          </div>
          <span v-if="item.slide_stikcy_image_text" v-text="item.slide_stikcy_image_text"></span>
        </router-link>
        <div class="a-slide-content-wrapp-container">
          <div class="firstSlider halfSlides tariffe-slider">
            <div class="tariffe-badge" v-if="showBadge">
              <span v-text="item.badge_text"></span>
            </div>
            <swiper ref="tariffeSlider" :options="sliderOptions">
              <template v-for="(subItem, i) in item.packages">
                <template v-if="subItem[activeItem]">
                  <swiper-slide :key="'swiper-i-4-' + i">
                    <div class="tariffe-inner-slide">
                      <div class="tariffe-slider--badge" v-if="subItem[activeItem].show_badge == 1">Empfehlung</div>
                      <div class="slide-title" :class="{ 'small': subItem[activeItem].title.length > 5 }" v-text="subItem[activeItem].title"></div>
                      <div v-if="subItem[activeItem].check_unlimited === '1' && cardTools.adult > 1" class="slide-subtitle font-weight-bold">
                        <img src="@/assets/images/intro13/unlimited_sign.png" width="80" alt="">
                      </div>
                      <div v-else class="slide-subtitle font-weight-bold" v-text="subItem[activeItem].speed"></div>
                      <ul class="slide-list-cont" v-if="subItem[activeItem] && subItem[activeItem].content_list.length">
                        <template v-for="(ssItem, ind) in subItem[activeItem].content_list">
                            <li
                            :key="'li-key-'+ind" v-text="ssItem.title"
                            :class="{
                                'text-primary font-weight-bold' : ssItem.text_purple == 1,
                                'show--tick' : ssItem.show_tick == 1
                            }"></li>
                        </template>
                        <li v-if="subItem[activeItem].check_unlimited == 1 && cardTools.adult > 1 && subItem[activeItem].unlimited_text"
                        :key="'li-key-extra-'" v-text="subItem[activeItem].unlimited_text"
                        class="text-primary font-weight-bold show--tick"></li>
                      </ul>
                      <div class="ml-4 mb-5">
                        <template v-if="subItem[activeItem].show_cashback && activeCashback">
                          <div class="text-primary font-weight-bold" v-for="(itm, j) in subItem[activeItem].cashback_list" :key="'cashback-' + j + '-' + i">
                            {{itm.cashback_row}}
                          </div>
                        </template>
                      </div>
                      <div class="slide-info" v-if="subItem[activeItem].info" v-text="subItem[activeItem].info"></div>
                      <div class="slide-price-wrapper">
                        <div class="slide-card-label" v-if="['eins', 'main'].includes(activeItem) && (cardTools.adult > 1 || cardTools.child > 0)">Für <span v-text="cardTools.adult + cardTools.child"></span> Karten:</div>
                        <div class="slide-price"  v-if="['eins', 'main'].includes(activeItem) && (cardTools.adult > 1 || cardTools.child > 0)">Ø <span v-text="filterPrice(subItem[activeItem].total / (cardTools.adult + cardTools.child))"></span> €</div>
                        <div class="slide-price" v-else v-text="filterPrice(subItem[activeItem].price) + ' €'"></div>
                        <div class="slide-total" v-if="['eins', 'main'].includes(activeItem) && (cardTools.adult > 1 || cardTools.child > 0)">Gesamtpreis <span v-text="filterPrice(subItem[activeItem].total)"></span> €</div>
                      </div>
                    </div>
                  </swiper-slide>
                </template>
              </template>
            <div class="swiper-pagination custom-pag-6" id="pagination-type-four" slot="pagination"></div>
            <div class="swiper-button-prev custom-pag-6" id="type-four-prev" slot="button-prev"></div>
            <div class="swiper-button-next custom-pag-6" id="type-four-next" slot="button-next"></div>
            </swiper>
          </div>
          <div class="a-slide-content-wrapp-filters">
            <div class="a-slide-content-wrapp-badge" v-if="item.sticky_badge" v-html="formatSticky(item.sticky_badge)"></div>
            <ul>
              <template v-for="(itm, i) in filters">
                <li :key="'slide-4-filters-' + i" v-if="item[itm.code + '_tooltip'] && item[itm.code + '_tooltip'] != '\r\n'">
                  <div class="checkpanel">
                    <div class="rowInput" :class="{ active: itm.active }">
                      <label class="toggleCheckbox medium">
                        <input v-model="itm.active" :name="itm.code" @change="handleInput(itm, i)" type="checkbox" class="hiddenInput"/>
                        <span>
                          <i>
                            <svg v-if="itm.active" xmlns="http://www.w3.org/2000/svg" width="11.339" height="8" viewBox="0 0 11.339 8">
                              <g id="icon_action_success_default" data-name="icon/action/success/default" transform="translate(-1.825 -4.825)">
                                <path id="Combined-Shape" d="M13.041,4.948a.4.4,0,0,0-.572,0L5.736,11.68,2.52,8.464a.4.4,0,0,0-.572.572l3.789,3.789,7.3-7.3A.4.4,0,0,0,13.041,4.948Z" fill="#e20074" fill-rule="evenodd"/>
                              </g>
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
                              <g id="icon_action_close_default" data-name="icon/action/close/default" transform="translate(-5.975 -5.975)">
                                <path id="Combined-Shape" d="M10.672,9.975l3.154-3.154a.493.493,0,0,0-.7-.7L9.975,9.278,6.821,6.124a.493.493,0,0,0-.7.7L9.278,9.975,6.124,13.129a.493.493,0,0,0,.7.7l3.154-3.154,3.154,3.154a.493.493,0,0,0,.7-.7Z" fill="#fff" fill-rule="evenodd"/>
                              </g>
                            </svg>
                          </i>
                        </span>
                      </label>
                      <p class="active" v-text="itm.title"></p>
                      <div class="tooltip-placeholder">
                        <button class="btn icon button-m-1" :class="'button-m-' + itm.id" @click.stop="handleTooltip('button-m-' + itm.id)" @blur="handleTooltip(null)">
                          <img :src="require('@/assets/images/intro14/icons/icon-i.png')" />
                        </button>
                      </div>
                      <div v-if="tooltip && tooltip === ('button-m-' + itm.id)" class="tooltip-holder right" v-html="item[itm.code + '_tooltip']"></div>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
            <div class="a-slide-content-wrapp-filters__calc"
                :class="{
                    'disabled': !['eins', 'main'].includes(activeItem),
                    'inactive': cardTools.adult < 2
                }">
              <div class="calcTitle">
                <h5>Wie viele Karten<br/> möchten Sie?
                  <div class="tooltip-placeholder">
                    <button class="btn icon config-m-1" :disabled="!['eins', 'main'].includes(activeItem)" :class="'config-m-1'" @click.stop="handleTooltip('config-m-1')" @blur="handleTooltip(null)">
                      <img :src="require('@/assets/images/intro14/icons/icon-i.png')" />
                    </button>
                  </div>
                  <div v-if="tooltip && tooltip === 'config-m-1'" class="tooltip-holder right" v-html="item.config_tooltip"></div>
                </h5>
              </div>
              <div class="calcRow">
                <span class="calcRow__title">Erwachsene</span>
                <div class="calcRow__tools">
                  <button class="btn decrease" :disabled="cardTools.adult < 2 || !['eins', 'main'].includes(activeItem)" @click="handleCards('a', '-')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="3" viewBox="0 0 21 3">
                      <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1.5 1.5) rotate(-90)">
                        <path id="Path_963" data-name="Path 963" d="M0,0V18" fill="none" stroke="#f9eaf2" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                      </g>
                    </svg>
                  </button>
                  <span class="currentValue" v-text="cardTools.adult"></span>
                  <button class="btn increase" :disabled="cardTools.adult === item.max_adult  || !['eins', 'main'].includes(activeItem)" @click="handleCards('a', '+')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1.5 1.5)">
                        <path id="Path_967" data-name="Path 967" d="M18,7.5v21" transform="translate(-7.5 -7.5)" fill="#f9eaf2" stroke="#e20074" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                        <path id="Path_968" data-name="Path 968" d="M7.5,18h21" transform="translate(-7.5 -7.5)" fill="#f9eaf2" stroke="#e20074" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
              <div class="calcRow">
                <span class="calcRow__title">Kinder unter 18</span>
                <div class="calcRow__tools">
                  <button class="btn decrease" :disabled="cardTools.child < 1 || !['eins', 'main'].includes(activeItem)" @click="handleCards('c', '-')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="3" viewBox="0 0 21 3">
                      <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1.5 1.5) rotate(-90)">
                        <path id="Path_963" data-name="Path 963" d="M0,0V18" fill="none" stroke="#f9eaf2" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                      </g>
                    </svg>
                  </button>
                  <span class="currentValue" v-text="cardTools.child"></span>
                  <button class="btn increase" :disabled="cardTools.child === item.max_children || !['eins', 'main'].includes(activeItem)" @click="handleCards('c', '+')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(1.5 1.5)">
                        <path id="Path_967" data-name="Path 967" d="M18,7.5v21" transform="translate(-7.5 -7.5)" fill="#f9eaf2" stroke="#e20074" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                        <path id="Path_968" data-name="Path 968" d="M7.5,18h21" transform="translate(-7.5 -7.5)" fill="#f9eaf2" stroke="#e20074" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                      </g>
                    </svg>
                  </button>
                </div>
              </div>
              <button class="btn resetCalculator" @click="resetCalculator">Reset</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    baseUrl: {
      type: String,
      required: true
    },
    filterId: {
      default: null
    }
  },
  watch: {
    activeItem: {
      handler (val) {
        this.filteredPackages()
        if (['eins', 'main'].includes(val)) {
          this.handleCalculator()
        } else {
          this.resetCalculator()
        }
      },
      immediate: true
    }
  },
  computed: {
    swiper () {
      return this.$refs?.tariffeSlider?.$swiper
    },
    activeItem () {
      let c = (this.filters[0].active && this.filters[1].active)
        ? 'mix'
        : (this.filters[0].active && !this.filters[1].active)
          ? 'eins'
          : (!this.filters[0].active && this.filters[1].active)
            ? 'young'
            : (this.filters[2].active ? 'prepaid' : 'main')

      this.checkForBadge(c)

      return c
    }
  },
  data () {
    return {
      showBadge: false,
      defSlides: [],
      activeCategory: 1,
      activeCashback: false,
      filters: [
        {
          id: 1,
          title: 'MagentaEINS',
          code: 'eins',
          active: false
        },
        {
          id: 2,
          title: 'Young',
          code: 'young',
          active: false
        },
        {
          id: 3,
          title: 'Prepaid',
          code: 'prepaid',
          active: false
        },
        {
          id: 4,
          title: 'Aktion',
          code: 'cashback',
          active: false
        }
      ],
      sliderOptions: {
        slidesPerView: 2,
        spaceBetween: 50,
        observer: true,
        observeSlideChildren: true,
        observeParents: true,
        navigation: {
          nextEl: '.swiper-button-next#type-four-next',
          prevEl: '.swiper-button-prev#type-four-prev'
        },
        pagination: {
          el: '.swiper-pagination#pagination-type-four',
          clickable: true
        }
      },
      cardTools: {
        adult: 2,
        child: 0
      },
      tooltip: null
    }
  },
  methods: {
    checkForBadge (active) {
      if (this.item) this.showBadge = this.item.show_badge && this.item.show_badge.indexOf(active) > -1
    },
    handleActiveSlides (id) {
      this.activeCategory = id
      this.swiper.slideTo(0)
      this.swiper.update()
    },
    handleInput (data, i) {
      if (data.code === 'cashback') {
        if (['young', 'prepaid', 'mix'].includes(this.activeItem)) {
          this.$nextTick(() => {
            this.$set(this.filters[i], 'active', false)
          })
        }
      }

      if (data.code === 'prepaid' && data.active) {
        for (let i = 0; i < this.filters.length; i++) {
          this.$set(this.filters[i], 'active', this.filters[i].code === data.code)
        }
      }

      if (['eins', 'young'].includes(data.code)) {
        this.$set(this.filters[2], 'active', false)
        if (data.code === 'young') this.$set(this.filters[3], 'active', false)
      }
      this.$nextTick(() => {
        this.activeCashback = this.filters[3].active
      })
    },
    handleCalculator () {
      for (let item in this.item.packages) {
        for (let innerItem in this.item.packages[item]) {
          if (['eins', 'main'].includes(innerItem)) {
            for (let i = 0; i < this.cardTools.adult; i++) {
              switch (i) {
                case 0:
                  this.item.packages[item][innerItem].total = parseFloat(this.item.packages[item][innerItem].price)
                  break
                case 1:
                  this.item.packages[item][innerItem].total += parseFloat(this.item.second_price)
                  break
                default:
                  this.item.packages[item][innerItem].total += parseFloat(this.item.other_price)
                  break
              }
            }
            this.item.packages[item][innerItem].total += (parseFloat(this.item.child_price) * this.cardTools.child)
          }
        }
      }
    },
    handleCards (type = null, operator = null) {
      if (!type || !operator) return
      switch (type) {
        case 'a':
          this.cardTools.adult = operator === '-' ? (this.cardTools.adult - 1) : (this.cardTools.adult + 1)
          break
        default:
          this.cardTools.child = operator === '-' ? (this.cardTools.child - 1) : (this.cardTools.child + 1)
          break
      }
      this.handleCalculator()
    },
    resetCalculator () {
      this.setTotals()
      this.cardTools = {
        adult: 1,
        child: 0
      }
    },
    setTotals () {
      for (let item in this.item.packages) {
        for (let innerItem in this.item.packages[item]) {
          this.$set(this.item.packages[item][innerItem], 'total', parseFloat(this.item.packages[item][innerItem].price))
        }
      }
    },
    handleTooltip (type = null) {
      this.tooltip = type
      if (this.tooltip) {
        let itemClass = document.querySelector('.' + this.tooltip)
        itemClass.focus()
      }
    },
    filteredPackages () {
      let startIndex = null
      let items = -1
      for (let itm in this.item.packages) {
        let item = this.item.packages[itm][this.activeItem]
        if (item) {
          items++
          if (startIndex === null && item.show_first === '1') {
            startIndex = items
          }
        }
      }
      this.$nextTick(() => {
        this.swiper && this.swiper.slideTo(startIndex || 0)
        this.swiper && setTimeout(() => {
          this.swiper.update()
        }, 800)
      })
    },
    formatSticky (data) {
      return data.includes('b>') ? data.replace('b>', 's>') : data.includes('strong>') ? data.replace('strong>', 's>') : data
    },
    filterPrice (val) {
      const newVal = parseFloat(val)
      if (isNaN(newVal)) return val // Return the original value if it's not a valid number

      // Format price with two decimals and replace '.' with ','
      let result = newVal.toFixed(2).replace('.', ',')

      // Apply discount if activeDiscount is set
      if (this.activeDiscount !== false && typeof this.activeDiscount === 'number') {
        const discountedVal = newVal - (newVal * (this.activeDiscount / 100))
        result = discountedVal.toFixed(2).replace('.', ',')
      }

      return result
    }
  },
  mounted () {
    this.filteredPackages()
    this.setTotals()
  }
}
</script>
