<template>
  <div class="phone-template-wrapper__container phone-bg-shadow">
    <div class="compare-phones" :class="expanded ? 'expanded' : ''">
      <div class="compare-phones__phone-wrapper">
        <div></div>
        <SmartphoneCompareBox :phone="firstPhone" :index="0" :manufacturers="manufacturers" />
        <SmartphoneCompareBox :phone="secondPhone" :index="1" :manufacturers="manufacturers" />
      </div>
      <div class="compare-phones__phone-details">
        <div class="compare-phones__phone-details__row" v-for="(prop, i) in phoneKeys" :key="'phone-itm-' + i">
          <div><b>{{prop.key}}:</b></div>
          <div class="pl-1 pr-5" v-text="firstPhone && firstPhone.properties && firstPhone.properties[i] ? firstPhone.properties[i].value : ''"></div>
          <div class="pl-1 pr-5" v-text="secondPhone && secondPhone.properties && secondPhone.properties[i] ? secondPhone.properties[i].value : ''"></div>
        </div>
        <div class="compare-phones__phone-details__row" v-if="expanded">
          <div><b>Produktinformation</b></div>
          <div class="pl-1 pr-5">
            <a v-if="firstPhone && firstPhone.download_document" :href="$store.state.slides.baseUrl + firstPhone.download_document" target="_blank" download>
              <img :src="require('@/assets/images/intro13/download-icon-white.svg')" />
            </a>
          </div>
          <div class="pl-1 pr-5">
            <a v-if="secondPhone && secondPhone.download_document" :href="$store.state.slides.baseUrl + secondPhone.download_document" target="_blank" download>
              <img :src="require('@/assets/images/intro13/download-icon-white.svg')" />
            </a>
          </div>
        </div>
      </div>
      <footer class="compare-phones__footer">
        <button @click="handleBack"><img :src="require('@/assets/images/intro13/back-arrow-white.svg')" /></button>
        <div class="compare-phones__footer__middle">
          <div>
            <button v-if="(firstPhone || secondPhone)" @click="clearSelection" class="mr-2">Reset</button>
            <button v-if="!firstPhone && !secondPhone && lastCompare && lastCompare.length === 2" @click="undoClear" class="mr-2">Undo</button>
            <button v-if="firstPhone || secondPhone"
            class="ml-2"
            @click="expanded = !expanded"
            v-text="expanded ? 'Zurück' : 'Mehr Produktdetails'"></button>
          </div>
        </div>
        <button @click="() => { firstPhone && secondPhone ? handlePrint() : '' }"><img :src="require('@/assets/images/intro13/print-icon-white.svg')" /></button>
      </footer>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SmartphoneCompareBox from '@/components/smartphone-comparison/smartphone-compare-box'
export default {
  components: {
    SmartphoneCompareBox
  },
  data () {
    return {
      expanded: false,
      manufacturers: [
        { id: 3, title: 'Apple' },
        { id: 9, title: 'Fairphone' },
        { id: 4, title: 'Google' },
        { id: 8, title: 'LG' },
        { id: 6, title: 'Motorola' },
        { id: 7, title: 'Nokia' },
        { id: 5, title: 'Nothing' },
        { id: 1, title: 'Samsung' },
        { id: 10, title: 'Telekom' },
        { id: 2, title: 'Xiaomi' },
        { id: 11, title: 'Crosscall' },
        { id: 12, title: 'Sonstige' }
      ],
      lastCompare: null
    }
  },
  computed: {
    ...mapGetters('smartphoneComparison', ['firstPhone', 'secondPhone', 'phones']),
    phoneKeys () {
      return this.phones?.length ? (this.expanded ? this.phones[0].properties : this.phones[0].properties?.slice(0, 4)) : []
    }
  },
  methods: {
    handleBack () {
      this.$store.commit('smartphoneComparison/setActiveView', this.$store.state?.smartphoneComparison?.lastView || 'category')
    },
    handlePrint () {
      this.$store.commit('smartphoneComparison/setPrint', { show: true, expanded: this.expanded })
    },
    clearSelection () {
      this.lastCompare = [this.firstPhone, this.secondPhone]
      this.$store.commit('smartphoneComparison/setPhonesCompare', { key: 'firstPhone', phone: null })
      this.$store.commit('smartphoneComparison/setPhonesCompare', { key: 'secondPhone', phone: null })
    },
    undoClear () {
      this.lastCompare?.length && this.$store.commit('smartphoneComparison/setPhonesCompare', { key: 'firstPhone', phone: this.lastCompare[0] })
      this.lastCompare?.length === 2 && this.$store.commit('smartphoneComparison/setPhonesCompare', { key: 'secondPhone', phone: this.lastCompare[1] })
      this.lastCompare = null
    }
  }
}
</script>
